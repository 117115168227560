nav {
  padding-top: 30px !important;
  padding-bottom: 0px !important;
  display: flex !important;
  justify-content: space-between !important;
  font-size: 20px !important;
  font-family: "Euro";
  color: #13003e !important;
  text-transform: uppercase !important;
  letter-spacing: 7px !important;
}

nav .navLinks {
  list-style: none;
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 0;
}
nav h2 {
  font-size: 20px;
  font-weight: bold;
  margin: 0;
}

/* nav  .navLinks .navSingleLink:first-of-type  ,
nav  .navLinks .navSingleLink:nth-of-type(2)
{
    margin-right: 5rem;
} */

nav .navLinks .navSingleLink:last-of-type {
  font-weight: bold;
  margin-left: 5rem;
}
.modifyLastLetter {
  position: relative;
  left: 5px;
}

nav .navLinks .navSingleLink a {
  color: inherit;
  text-decoration: inherit;
  font-weight: inherit;
  font-size: 16px;
}

@media (max-width: 989px) {
  nav .navLinks {
    flex-direction: column;
  }
  .modifyLastLetter {
    position: relative;
    left: 0px;
  }
  nav .navLinks .navSingleLink:last-of-type {
    margin-left: 0;
  }
}
