.sliderContainer {
  margin-top: 70px;
  font-family: "euro";
  height: 85vh;
}

.sliderContainer .row {
  margin-bottom: 20px;
}

.sliderContainer .sliderInfo {
  transform: translateY(70px);
}
@media (max-width: 989px) {
  .sliderContainer .sliderInfo {
    transform: translateY(0px);
  }
}
.sliderContainer .types {
  cursor: pointer;
}

.sliderContainer .sliderHeaders {
  font-size: 20px;
  letter-spacing: 7px;
  font-weight: bold;
  text-transform: uppercase;
  color: #13003e;
  margin-bottom: 50px;
}
.sliderContainer .sliderParagraph {
  font-size: 16px;
  color: #13003e;
  font-weight: normal;
}
.sliderContainer .indicators {
  margin-top: 150px;
}
.sliderContainer .indicators span {
  color: #13003e;
  font-weight: bold;
  font-size: 18px;
}
.sliderContainer .indicators svg {
  cursor: pointer;
}

.sliderContainer .sliderLine {
  width: 100%;
  height: 1px;
  background-color: #13003e;
  margin-top: 30px;
  margin-bottom: 30px;
  position: relative;
}
.sliderContainer .sliderLine .beforePseudoElement {
  content: "";
  height: 2px;
  background-color: #13003e;
  position: absolute;
  top: -0.5px;
  left: 0;
}
