.loading {
  font-family: "Euro";
  color: #ffffff;
  background-image: url("../../assets/images/AdobeStock_222960005@2x.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  transition: 2s;
}

.scrollableContent {
  height: 100%;
}

.loading .loadingHeader {
  font-family: "Euro";
  font-weight: normal;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.loading .loadingHeader h1 {
  font-size: 100px;
  letter-spacing: 35px;
}

.loading .loadingScroll {
  position: absolute;
  top: 90%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.loading .loadingScroll span {
  font-size: 24px;
  font-weight: bold;
}

.loading .loadingScroll:hover .arrowdwn {
  animation-name: upNdown;
  animation-duration: 0.5s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

@keyframes upNdown {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(0px);
  }
}

@media screen and (max-width: 768px) {
  .loading .loadingHeader h1 {
    font-size: 60px;
    letter-spacing: 20px;
  }
  .loading .loadingScroll span {
    font-size: 20px;
  }
}

@media screen and (max-width: 480px) {
  .loading .loadingHeader h1 {
    font-size: 30px;
    letter-spacing: 10px;
  }
  .loading .loadingScroll span {
    font-size: 14px;
  }
}
