.typesContainer {
  font-family: "euro";
  height: 85vh;
}

.typesContainer .row {
  margin-bottom: 20px;
}

.typesContainer .typesInfo {
  transform: translateY(200px);
}

@media (max-width: 989px) {
  .typesContainer .typesInfo {
    transform: translateY(0px);
    margin-top: 50px;
  }
}
.typesContainer .types {
  cursor: pointer;
}

.typesContainer .typesHeaders {
  cursor: pointer;
  font-size: 16px;
  letter-spacing: 7px;
  font-weight: bold;
  text-transform: uppercase;
  color: #13003e;
  margin-bottom: 50px;
  margin-top: 3px;
}
.typesContainer .typesParagraph {
  font-size: 16px;
  color: #13003e;
  font-weight: normal;
}

.typesContainer .typesView {
  cursor: pointer;
}
.effect1,
.effect2,
.effect3 {
  position: relative;
}

.effect1::before {
  content: url("../../assets/images/AdobeStock_221152595.png");
  position: absolute;
  top: -280px;
  right: 235px;
  z-index: -1;
}
.effect2::before {
  content: url("../../assets/images/AdobeStock_221152781.png");
  position: absolute;
  top: -80%;
  right: 40%;
  z-index: -1;
}
.effect3::before {
  content: url("../../assets/images/AdobeStock_220542447.png");
  position: absolute;
  top: -225px;
  right: 255px;
  z-index: -1;
}
.effect1 img,
.effect2 img,
.effect3 img {
  max-width: 85% !important;
}

@media (max-width: 1399px) {
  .effect1::before {
    top: -370px;
    right: 235px;
  }
  .effect2::before {
    top: -105%;
    right: 35%;
  }
}
@media (max-width: 1199px) {
  .effect1::before {
    top: -480px;
    right: 170px;
  }
  .effect2::before {
    top: -128%;
    right: 35%;
  }
}

@media (max-width: 989px) {
  .effect1::before {
    content: none;
  }
  .effect2::before {
    content: none;
  }
  .effect3::before {
    content: none;
  }
}

.typesContainer .indicators span {
  color: #13003e;
  font-weight: bold;
  font-size: 18px;
}
.typesContainer .indicators svg {
  cursor: pointer;
}

.typesContainer .typesLine {
  width: 100%;
  height: 1px;
  background-color: #13003e;
  margin-top: 30px;
  margin-bottom: 50px;
  position: relative;
}
.typesContainer .typesLine .beforePseudoElement {
  content: "";
  height: 2px;
  background-color: #13003e;
  position: absolute;
  top: -0.5px;
  left: 0;
}

.backIcon {
  margin-right: 10px;
}
.forwardIcon,
.backIcon {
  cursor: pointer;
}
