.contact {
  margin-top: 110px;
  font-family: "euro";
  overflow-y: hidden;
}

.contact::after {
  content: url("../../assets/images/contact.png");
  position: fixed;
  top: 0;
  left: -140px;
  z-index: -1;
}

.contact .contactHeading {
  margin-bottom: 24px;
  font-weight: bold;
  color: #02131a;
}

.contact .contactParagraph {
  font-size: 18px;
  color: #02131a;
  font-weight: 500;
  margin-bottom: 46px;
}
.contact .groupOne {
  margin-bottom: 35px;
}
.contact .groupOne div,
.contact .groupTwo div {
  width: 45%;
}

.contact .groupOne div label,
.contact .groupTwo div label {
  color: #02131a;
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 13px;
}

.contact .groupOne div input,
.contact .groupTwo div input {
  border-radius: 10px;
  padding: 10px 24px;
  /* width: 160%; */
  border: 0.5px solid #02131a;
  font-size: 20px;
  color: #02131a;
}

.contact .contactButton button {
  all: unset;
  font-size: 20px;
  color: #02131a;
  text-decoration: underline;
  margin-top: 35px;
  cursor: pointer;
}
